import { useContext } from 'react';
import { store } from 'src/lib/store/state';
import { ComboBox } from 'carbon-components-react';
import useFilterOptions from 'src/lib/hooks/useFilterOptions';

const KDFilter = (props) => {
  const { globalState, dispatch } = useContext(store);

  const filter = props.filter;

  const { options, data, loading, error } = useFilterOptions(
    filter.id,
    filter.query.index,
    filter.query.body,
    filter.query.instances[0]
  );

  const handleFilterChange = (e) => {
    // update context in the global state
    dispatch({
      type: 'SET_SELECTED_FILTER',
      payload: {
        filterId: filter.id,
        value: e.selectedItem
      }
    });
  };

  return (
    <ComboBox
      id={filter.id}
      titleText={filter.label}
      placeholder={'Select ' + filter.label}
      items={options}
      itemToString={(option) => (option ? option.text : '')}
      selectedItem={globalState.account.selectedFilters[filter.id]}
      onChange={(e) => handleFilterChange(e)}
      shouldFilterItem={({ item: { text }, inputValue }) => {
        if (text && inputValue) { return text?.toLowerCase().startsWith(inputValue?.toLowerCase()); }
        return true;
      }}
    />
  );
};

export default KDFilter;
