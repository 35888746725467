import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { store } from 'src/lib/store/state';

export default function useLanding() {
  const { globalState, dispatch } = useContext(store);

  let filters;

  function handleResponse(response) {
    if (!response.ok) {
      return Promise.reject(response);
    };
    return response.json();
  };

  const fetcher = (url) => fetch(url).then(handleResponse);

  // fetch widget data
  const { data: landingData, error: landingError } = useSWR(
    globalState.account.id && globalState.user.userEmail
      ? '/dashboards/api/proxy/dashboards/dashboards/v1/landing-page/default?account=' +
      globalState.account.id
      : null, fetcher, { revalidateOnFocus: false, revalidateIfStale: false, shouldRetryOnError: false, revalidateOnReconnect: false }
  );

  const landingLoading = !landingData && !landingError;

  if (landingData && landingData.config) {
    filters = getFilters(landingData.config.filter);
  }

  // suppress failure message only if landing page not found 404.  
  let err = (landingError?.status === 404) ? undefined : landingError;

  return { filters, landingData, landingLoading, err };
}

const getFilters = (filtersObj) => {
  let filters = [];

  for (const [key, value] of Object.entries(filtersObj)) {
    // filter out _query or any other special non-filter keys
    if (!key.startsWith('_')) {
      filters[value.index] = {
        key: key,
        id: value.key,
        label: value.displayName['en-US'],
        query: value.query || filtersObj._query // use filter query if present, else use global _query
      };
    }
  }

  return filters;
};
