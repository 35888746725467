import { useContext, useState, useEffect } from 'react';
import { store } from 'src/lib/store/state';
import { useRouter } from 'next/router';
import { Vega, VegaLite } from 'react-vega';
import { ClickableTile } from 'carbon-components-react';
import useWidgetData from 'src/lib/hooks/useWidgetData';
import { buildQs } from 'src/lib/helpers/helpers';
import { cloneDeep } from 'lodash';

const transformSpec = (spec, data) => {
  let finalSpec = cloneDeep(spec);
  finalSpec.data[0].values = data;

  // add default width, height if not available in spec
  finalSpec.width = (finalSpec.width) ? finalSpec.width : 428;
  finalSpec.height = (finalSpec.height) ? finalSpec.height : 260;
  finalSpec.padding = (finalSpec.padding)? finalSpec.padding: 0;
  finalSpec.autosize = { type: 'fit', contains: 'padding' };

  return finalSpec;
};

const KDWidget = (props) => {
  const router = useRouter();

  // access global state context
  const { globalState, dispatch } = useContext(store);

  const accountQs = buildQs(globalState);
  const spec = props.widget.visualization.spec;
  const key = props.widget.account + '-' + props.widget.name;

  // call widget data api hook
  const { data, loading, error } = useWidgetData(
    key,
    spec.data[0],
    globalState.account.selectedFilters,
    props.widget.esInstance
  );

  // combine the data with the spec
  const finalSpec = transformSpec(spec, data);

  // hard-code widget size based on spec so it doesn't jump after loading
  const sizeStyles = {
    width: finalSpec.width + 32,
    height: finalSpec.height + 32
  };

  return (
    <div className="widget">
      <ClickableTile
        href={`/dashboards/dashboard/${props.widget.dashboardId}${accountQs}`}
        target="_blank"
        rel="noopener"
        className={`widget__wrapper ${loading ? 'loading' : null}`}
        style={sizeStyles}
        handleClick={(e) => {
          // load with client-side router, much faster
          e.preventDefault();
          router.push(`/dashboard/${props.widget.dashboardId}${accountQs}`);
        }}
      >
        {/* {data && <VegaLite spec={finalSpec} actions={false} />} */}
        {data && <Vega spec={finalSpec} actions={false} />}

        {error && (
          <span className="error-text">Failed to load widget data.</span>
        )}
      </ClickableTile>
    </div>
  );
};

export default KDWidget;
