import useSWR from 'swr';
import { buildMatchPhrase } from 'src/lib/helpers/helpers';
import { cloneDeep, get } from 'lodash';

export default function useWidgetData(key, vegaData, selectedFiltersData, esInstance) {
  // swr version of the es query request

  let index = vegaData.url.index;
  let context = false;
  if (index) {
    index = `/${index}`;
  }

  let vega = cloneDeep(vegaData);
  let selectedFilters = cloneDeep(selectedFiltersData);

  const map = vega.url["%map%"];
  if (map) {
    for (let [documentAtt, mapVal] of Object.entries(map)) {
      mapVal = mapVal.replace('/~{{([^}]*)}}/g').replace('{{', '').replace('}}', '');
      const mapArr = mapVal.split('.');

      const filterSrc = selectedFilters[mapArr[0]]?.src;
      let shouldFilterArr = [];
      if (filterSrc?.length > 0) {
        filterSrc.forEach(src => {
          const srcVal = src[mapArr[2]];
          if (srcVal) {
            shouldFilterArr.push(srcVal);
          }
        });
      }
      if (shouldFilterArr.length > 0) {
        selectedFilters[documentAtt] = { id: [], shouldFilterArr };
        delete selectedFilters[mapArr[0]]; // delete original filter when map is present
      }
    }
  }

  const customFilter = buildMatchPhrase(selectedFilters);

  if (vega.url["%context%"]) {
    context = true;
  }

  // replace special filter token with custom filter options when context is false
  if (customFilter && !context) {
    if (!vega.url.body.query.bool.filter) {
      vega.url.body.query.bool.filter = [];
    }
    const defaultFilter = JSON.stringify(vega.url.body.query.bool.filter);
    const exists = defaultFilter.search("%dashboard_context-filter_clause%");
    if (exists !== -1) {
      var tempArr = [];
      customFilter.forEach(x => {
        tempArr.push(JSON.stringify(x));
      });
      const temp = defaultFilter.replace(`"%dashboard_context-filter_clause%"`, tempArr.join(',')).replace(/[,]{2,}/, ",").replace('[,','[').replace(',]',']');
      vega.url.body.query.bool.filter = JSON.parse(temp);
    }
  }

  // construct query object when context is true 
  if (context) {
    vega.url.body.query = {
      "bool": {
        "must": [],
        "filter": [
          ...customFilter
        ],
        "should": [
        ],
        "must_not": []
      }
    };
  }

  const url = `/dashboards/api/proxy/dashboards/dashboards/v1/es/${esInstance}/proxy${index}/_search`;

  const fetcher = (...args) =>
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(vega.url.body)
    }).then((res) => res.json());

  const { data, error } = useSWR(
    vega.url.body ? [url, key, JSON.stringify(selectedFilters)] : null,
    fetcher, { revalidateOnFocus: false, revalidateIfStale: false, shouldRetryOnError: false, revalidateOnReconnect: false }
  );

  const loading = !data && !error;

  return { data, loading, error };
}
