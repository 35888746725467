import useSWR from 'swr';

export default function useFilterOptions(key, index, query, esInstance) {
  if (index) {
    index = `/${index}`;
  }

  const url =
    `/dashboards/api/proxy/dashboards/dashboards/v1/es/${esInstance}/proxy${index}/_search`;

  const fetcher = (...args) =>
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(query)
    }).then((res) => res.json());

  const { data, error } = useSWR(query ? [url, key] : null, fetcher, { revalidateOnFocus: false, revalidateIfStale: false, shouldRetryOnError: false, revalidateOnReconnect: false });

  // build the options array
  let options = [];

  if (data && data.hits && data.hits.hits?.length > 0) {
    const items = data.hits.hits;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      // make sure option is unique
      if (
        !options.some((option) => {
          return option.id == item._source[key];
        })
      ) {
        // push new option
        options.push({
          id: item._source[key],
          text: item._source[key],
          src: [item._source]
        });
      }
      else {
        // update source object in existing option
        const index = options.findIndex(o => o.id === item._source[key]);
        let existingOption = options[index];
        existingOption.src.push(item._source);
        options[index] = existingOption;
      }
    }
  }
  else if (data && data.aggregations && Object.keys(data.aggregations).length > 0) {
    const buckets = data.aggregations[Object.keys(data.aggregations)[0]].buckets;
    if (buckets) {
      buckets.forEach(bucket => {
        options.push({
          id: bucket.key,
          text: bucket.key
        });
      });
    }
  }

  // sorting the options
  options.sort((a, b) => {
    if (!a?.text || !b?.text) {
      return 0;
    }
    let item1 = a.text.toLowerCase(),
      item2 = b.text.toLowerCase();

    if (item1 < item2) {
      return -1;
    }
    if (item1 > item2) {
      return 1;
    }
    return 0;
  });

  const loading = !data && !error;

  return { options, data, loading, error };
}
